@tailwind base;
@tailwind components;
@tailwind utilities;
/* Hide scrollbars */
html,
body {
  overflow-x: hidden;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
